/* ========================================================================
 * template
 * ======================================================================== */
body.app {
  background: #1c0026 url("/images/pinnacle-bet.com/bg.jpg") center top no-repeat;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
}

.content-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-login {
  margin-top: 10%;
  border-radius: 10px;
  background: #220d42;
  padding: 30px 50px;
  color: #000033;
  border-width: 0;
  font-size: 1em;
}

.panel-login .panel-body {
  padding: 0;
  max-width: 300px;
}

.panel-login .form-group {
  margin-right: 0;
  margin-left: 0;
}

.panel-login .btn-primary {
  background: #ff471c;
  padding: 6px 25px;
  border-width: 0;
  border-radius: 3px;
  width: 100%;
  font-size: 1.375em;
  text-transform: uppercase;
  height: 50px;
}

.panel-login .btn-primary:hover {
  background: #e2401a;
  transition: all 500ms ease;
}

.panel-login .btn-primary:active {
  background: #e2401a;
  border-width: 0;
}

.panel-login .copyright {
  text-transform: uppercase;
  color: #666;
  text-align: center;
  font-size: 0.875em;
}

.panel-login .form-control {
  font-size: 1em;
  color: #666;
  border-radius: 3px;
  height: 50px;
  border: 2px solid #ddd;
  padding: 10px 20px;
  margin-right: 0;
  margin-left: 0;
}

.panel-login .form-control:focus {
  border: 2px solid #bbb;
}

.panel-login .logo {
  margin-bottom: 28px;
}

.panel-login #account {
  text-transform: uppercase;
}

.panel-login .help-block {
  color: red;
}

.panel-login #account::-webkit-input-placeholder {
  text-transform: none;
}

.panel-login #account::-moz-placeholder {
  text-transform: none;
}

.panel-login #account:-ms-input-placeholder {
  text-transform: none;
}

.panel-login #account:-moz-placeholder {
  text-transform: none;
}

@media only screen and (max-width: 325px) {
  .panel-login .copyright {
    font-size: 0.7em;
  }
  .panel-login {
    padding: 30px 30px;
  }
}
